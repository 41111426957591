import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Calendar as CalendarIcon} from 'lucide-react';
import Calendar from '../components/Calendar';
import GoogleMap from '../components/GoogleMap';

const MeetAuthorPage = () => {
  // 가상의 만남 일정 데이터
  const events = [
    { date: '2024-09-12', time: '18:00', title: '시 버스킹(우천 취소...)', location: '신촌 아리수 공연장' },
    { date: '2024-09-19', time: '19:00 ~ 20:00', title: '시 버스킹', location: '신촌 아리수 공연장' },
    { date: '2024-09-28', time: '12:00 ~ 17:00', title: '열정마켓(플리마켓)', location: '금천구청역, 금천구청 선큰광장' },
    { date: '2024-10-08', time: '10:00 ~ 20:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-09', time: '10:00 ~ 20:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-10', time: '10:00 ~ 20:00', title: 'ㅁYㅇ 익선 팝업스토어', location: '익선스페이스' },
    { date: '2024-10-19', time: '13:00 ~ 19:00', title: '신촌 청년잡화점/책부상 북페어', location: '대현문화공원' },
    { date: '2024-10-26', time: '13:00 ~ 19:00', title: '신촌 청년잡화점', location: '대현문화공원' },
  ];

  // 2024년 9월과 10월의 날짜 데이터 생성
  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month - 1, 1).getDay();
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month - 1, i));
    }

    return days;
  };

  const september = generateCalendarDays(2024, 9);
  const october = generateCalendarDays(2024, 10);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      
      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-teal-700 mb-8 text-center font-rbt">ㅁYㅇ 와의 만남</h1>
        
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <div className="flex items-center mb-6">
            <CalendarIcon className="w-8 h-8 text-teal-500 mr-3" />
            <h2 className="text-2xl font-semibold text-teal-700 font-rbt">다가오는 일정</h2>
          </div>
          <div className="bg-teal-50 border-l-4 border-teal-500 p-4 mb-6 rounded-r-lg">
            <p className="text-teal-700 font-medium mb-2">일정 안내</p>
            <ul className="list-disc list-inside text-gray-700 space-y-1">
              <li>작가와의 만남 일정은 변경될 수 있습니다.</li>
              <li>정확한 일정과 장소는 별도 공지를 확인해 주세요.</li>
              <li>참여를 원하시는 분은 언제든 오셔도 좋습니다!</li>
              <li>저희가 따뜻하게 맞이하겠습니다.</li>
            </ul>
          </div>
          <Calendar month={9} year={2024} days={september} events={events} />
          <Calendar month={10} year={2024} days={october} events={events} />
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-teal-700 font-rbt">이벤트 위치</h2>
          <GoogleMap 
            src="https://www.google.com/maps/d/u/0/embed?mid=1hVQb2GVz3_k6SG241fd-vqYGlz3_CGE&ehbc=2E312F"
            title="ㅁYㅇ 이벤트 위치 지도"
          />
        </div>

      </main>

      <Footer />
    </div>
  );
};

export default MeetAuthorPage;
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

const Book3D = ({ frontImage, backImage, sideImage, width = 140, height = 200 }) => {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    // 회색 배경 추가
    scene.background = new THREE.Color(0xe6e6e6);  // 밝은 회색

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: false });
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio);

    const currentMount = mountRef.current;
    currentMount.appendChild(renderer.domElement);

    const geometry = new THREE.BoxGeometry(1.28, 1.82, 0.06);
    
    const textureLoader = new THREE.TextureLoader();
    const loadTexture = (image) => {
      const texture = textureLoader.load(image);
      texture.minFilter = THREE.LinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
      return texture;
    };

    const frontTexture = loadTexture(frontImage);
    const backTexture = loadTexture(backImage);
    const sideTexture = loadTexture(sideImage);

    const materials = [
      new THREE.MeshStandardMaterial({ map: sideTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
      new THREE.MeshStandardMaterial({ map: sideTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
      new THREE.MeshStandardMaterial({ map: sideTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
      new THREE.MeshStandardMaterial({ map: sideTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
      new THREE.MeshStandardMaterial({ map: frontTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
      new THREE.MeshStandardMaterial({ map: backTexture, emissive: 0xffffff, emissiveIntensity: 0.2 }),
    ];

    const book = new THREE.Mesh(geometry, materials);
    scene.add(book);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1.5);
    pointLight.position.set(2, 2, 2);
    scene.add(pointLight);

    const frontLight = new THREE.DirectionalLight(0xffffff, 1.5);
    frontLight.position.set(0, 0, 5);
    scene.add(frontLight);

    camera.position.z = 2;

    const animate = () => {
      requestAnimationFrame(animate);
      book.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();

    return () => {
      currentMount.removeChild(renderer.domElement);
    };
  }, [frontImage, backImage, sideImage, width, height]);

  return <div ref={mountRef} style={{ width: `${width}px`, height: `${height}px` }} />;
};

export default Book3D;
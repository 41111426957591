import { useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebase';
import { 
  onAuthStateChanged,
  signInWithPopup,
  signOut, 
} from 'firebase/auth';

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // 이메일/비밀번호 관련 함수들은 주석 처리
  /*
  const signup = async (email, password) => {
    // ... (주석 처리)
  };

  const login = async (email, password) => {
    // ... (주석 처리)
  };
  */

  const logout = async () => {
    setError(null);
    try {
      await signOut(auth);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const loginWithGoogle = async () => {
    setError(null);
    try {
      await signInWithPopup(auth, googleProvider);
      return true;
    } catch (error) {
      setError('구글 로그인 중 오류가 발생했습니다. 다시 시도해 주세요.');
      return false;
    }
  };

  return { user, error, loading, logout, loginWithGoogle };
};
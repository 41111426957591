import React, { useState, useEffect } from 'react';
import { ChevronDown, ChevronUp, Book, User, SortAsc, Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import Header from '../components/ToOurReaders/Header';
import samplePoems from '../data/samplePoems';
import Footer from '../components/Footer';

const NotesPage = () => {
  const [poems, setPoems] = useState([]);
  const [selectedPoem, setSelectedPoem] = useState(null);
  const [viewMode, setViewMode] = useState('title'); // 'title' or 'author'
  const { user, loading } = useAuth();

  useEffect(() => {
    setPoems(samplePoems);
  }, []);

  const sortedPoems = React.useMemo(() => {
    if (viewMode === 'title') {
      return [...poems];
    } else {
      return [...poems].sort((a, b) => a.author.localeCompare(b.author));
    }
  }, [poems, viewMode]);

  const groupedPoems = React.useMemo(() => {
    if (viewMode === 'author') {
      return sortedPoems.reduce((acc, poem) => {
        if (!acc[poem.author]) {
          acc[poem.author] = [];
        }
        acc[poem.author].push(poem);
        return acc;
      }, {});
    }
    return null;
  }, [sortedPoems, viewMode]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-100 via-teal-50 to-green-200">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-teal-500"></div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-200">
        <Header />
        <main className="container mx-auto px-6 py-16 flex flex-col items-center justify-center">
          <h2 className="text-4xl font-bold text-teal-700 mb-8 font-rbt">로그인이 필요합니다</h2>
          <Link 
            to="/readerauth" 
            className="bg-teal-500 text-white px-8 py-4 rounded-full hover:bg-teal-600 transition-colors text-lg font-semibold shadow-lg hover:shadow-xl transform hover:-translate-y-1"
          >
            로그인 페이지로 이동
          </Link>
        </main>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-200">
      <Header />

      <main className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h2 className="text-3xl font-bold text-teal-700 mb-6 font-rbt flex items-center"> {/* 크기 줄임 */}
          <Book className="mr-3" size={30} /> {/* 아이콘 크기 줄임 */}
          창작노트
        </h2>

        <div className="flex space-x-4 mb-5"> {/* 여백 줄임 */}
          <button
            onClick={() => setViewMode('title')}
            className={`flex items-center px-3 py-1 rounded-md text-sm transition-colors ${
              viewMode === 'title' ? 'bg-teal-500 text-white' : 'bg-white text-teal-500 hover:bg-teal-100'
            }`}
          >
            <SortAsc className="mr-1" size={16} />
            작품명 순
          </button>
          <button
            onClick={() => setViewMode('author')}
            className={`flex items-center px-3 py-1 rounded-md text-sm transition-colors ${
              viewMode === 'author' ? 'bg-teal-500 text-white' : 'bg-white text-teal-500 hover:bg-teal-100'
            }`}
          >
            <Users className="mr-1" size={16} />
            작가별
          </button>
        </div>
        
        <div className="space-y-4"> {/* 간격 줄임 */}
          {viewMode === 'title' ? (
            sortedPoems.map((poem) => (
              <PoemCard key={poem.id} poem={poem} selectedPoem={selectedPoem} setSelectedPoem={setSelectedPoem} />
            ))
          ) : (
            Object.entries(groupedPoems).map(([author, poems]) => (
              <div key={author} className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl p-4"> {/* 패딩 줄임 */}
                <h3 className="text-xl font-semibold text-teal-600 mb-3">{author}</h3> {/* 크기와 여백 줄임 */}
                <div className="space-y-3"> {/* 간격 줄임 */}
                  {poems.map((poem) => (
                    <PoemCard key={poem.id} poem={poem} selectedPoem={selectedPoem} setSelectedPoem={setSelectedPoem} showAuthor={false} />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </main>

      <Footer />
    </div>
  );
};

const PoemCard = ({ poem, selectedPoem, setSelectedPoem, showAuthor = true }) => (
  <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
    <div 
      className="p-4 cursor-pointer" /* 패딩 줄임 */
      onClick={() => setSelectedPoem(selectedPoem?.id === poem.id ? null : poem)}
    >
      <div className="flex justify-between items-center mb-1"> {/* 여백 줄임 */}
        <h3 className="text-lg font-semibold text-teal-600 hover:text-teal-700 transition-colors">{poem.title}</h3> {/* 크기 줄임 */}
        {selectedPoem?.id === poem.id ? <ChevronUp className="text-teal-500" size={20} /> : <ChevronDown className="text-teal-500" size={20} />} {/* 아이콘 크기 줄임 */}
      </div>
      {showAuthor && (
        <div className="flex items-center text-gray-600">
          <User size={14} className="mr-1" /> {/* 아이콘 크기와 여백 줄임 */}
          <span className="text-xs">{poem.author}</span> {/* 크기 줄임 */}
        </div>
      )}
    </div>
    {selectedPoem?.id === poem.id && (
      <div className="p-4 bg-teal-50 border-t border-teal-100"> {/* 패딩 줄임 */}
          <div className="text-gray-700 mb-4 text-sm leading-relaxed whitespace-pre-wrap space-y-4">
            {poem.content.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        {poem.explanation && (
          <div className="bg-white p-3 rounded-lg shadow-inner"> {/* 패딩 줄임 */}
            <h4 className="font-semibold text-teal-700 mb-2 text-base">설명:</h4> {/* 크기와 여백 줄임 */}
            <p className="text-gray-600 text-sm">{poem.explanation}</p> {/* 크기 줄임 */}
          </div>
        )}
      </div>
    )}
  </div>
);

export default NotesPage;
import React from 'react';

const GoogleMap = ({ src, title }) => {
  return (
    <div className="w-full h-[480px]">
      <iframe 
        src={src}
        width="100%" 
        height="100%" 
        style={{ border: 0 }} 
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        title={title}
      ></iframe>
    </div>
  );
};

export default GoogleMap;
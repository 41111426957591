import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

const Calendar = ({ month, year, events }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(month);
  const [currentYear, setCurrentYear] = useState(year);

  const monthNames = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];

  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month - 1, 1).getDay();
    const daysInMonth = new Date(year, month, 0).getDate();
    const days = [];

    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month - 1, i));
    }

    return days;
  };

  const days = generateCalendarDays(currentYear, currentMonth);

  const formatDate = (date) => {
    return date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : '';
  };

  const handleDateClick = (day) => {
    setSelectedDate(day);
  };

  const handlePrevMonth = () => {
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const selectedDateEvents = selectedDate
    ? events.filter(event => event.date === formatDate(selectedDate))
    : [];

  return (
    <div className="bg-white rounded-lg shadow-md p-3 max-w-lg mx-auto">
      <div className="flex justify-between items-center mb-3">
        <button onClick={handlePrevMonth} className="p-1.5 rounded-full hover:bg-gray-100">
          <ChevronLeft className="w-5 h-5 text-teal-600" />
        </button>
        <h2 className="text-base font-bold text-teal-700">
          {monthNames[currentMonth - 1]} {currentYear}
        </h2>
        <button onClick={handleNextMonth} className="p-1.5 rounded-full hover:bg-gray-100">
          <ChevronRight className="w-5 h-5 text-teal-600" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-2 mb-3">
        {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
          <div key={day} className="text-center text-sm font-medium text-teal-600">{day}</div>
        ))}
        {days.map((day, index) => {
          const dateString = formatDate(day);
          const hasEvents = events.some(event => event.date === dateString);

          return (
            <button
              key={index}
              onClick={() => day && handleDateClick(day)}
              className={`
                aspect-square p-1 rounded transition-all duration-300 ease-in-out text-sm
                ${day ? 'hover:bg-teal-100' : 'bg-gray-50'}
                ${hasEvents ? 'bg-teal-200 font-bold' : 'bg-white'}
                ${selectedDate && day && selectedDate.getTime() === day.getTime() ? 'ring-2 ring-teal-500' : ''}
              `}
              disabled={!day}
            >
              {day && (
                <div className="w-full h-full flex justify-center items-center">
                  <span className={`${hasEvents ? 'text-teal-800' : 'text-gray-700'}`}>
                    {day.getDate()}
                  </span>
                </div>
              )}
            </button>
          );
        })}
      </div>

      {selectedDate && selectedDateEvents.length > 0 && (
        <div className="mt-3 bg-teal-50 rounded p-3 relative">
          <button
            onClick={() => setSelectedDate(null)}
            className="absolute top-2 right-2 text-teal-600 hover:text-teal-800"
          >
            <X className="w-5 h-5" />
          </button>
          <h3 className="text-sm font-bold text-teal-800 mb-2">
            {selectedDate.getFullYear()}년 {selectedDate.getMonth() + 1}월 {selectedDate.getDate()}일 일정
          </h3>
          {selectedDateEvents.map((event, index) => (
            <div key={index} className="bg-white rounded p-2 mb-2 last:mb-0 shadow-sm">
              <p className="text-sm font-semibold text-teal-700">{event.title}</p>
              <p className="text-xs text-gray-600">{event.time} - {event.location}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Calendar;
import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import PsychTest from '../components/PsychTest';
import Footer from '../components/Footer';

const PsychTestPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      <motion.main 
        className="container mx-auto px-4 py-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-4xl font-bold text-teal-600 mb-8 text-center font-rbt">그대의 시 취향은?</h1>
        <p className="text-gray-700 text-center mb-8">
          몇 가지 질문에 답하고 당신의 시 취향을 알아보세요. 꼭 맞는 시집을 얻어가실 수 있을 거예요!
        </p>
        <PsychTest />
      </motion.main>

      <Footer />
    </div>
  );
};

export default PsychTestPage;
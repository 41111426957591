import React, { useState, useRef, useEffect } from 'react';
import { Book, Menu, ShoppingCart, User} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const userMenuRef = useRef(null);
  let timeoutId = null;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserIconClick = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleUserMenuEnter = () => {
    clearTimeout(timeoutId);
    setIsUserMenuOpen(true);
  };

  const handleUserMenuLeave = () => {
    timeoutId = setTimeout(() => {
      setIsUserMenuOpen(false);
    }, 300); // 300ms delay before closing
  };

  return (
    <header className="bg-white bg-opacity-95 sticky top-0 z-10 shadow-sm">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/main" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
          <Book className="h-8 w-8 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" style={{color: '#4A90E2'}}/>
          <h1 className="text-2xl font-bold text-sky-300 font-aggro tracking-wide" style={{color: '#4A90E2'}}>ㅁYㅇ</h1>
        </Link>
        <div className="flex items-center space-x-6">
          <Link to="/sales">
            <ShoppingCart className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" />
          </Link>
          {user ? (
            <div 
              className="relative"
              ref={userMenuRef}
              onMouseEnter={handleUserMenuEnter}
              onMouseLeave={handleUserMenuLeave}
            >
              <User 
                className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" 
                onClick={handleUserIconClick}
              />
              {isUserMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
                  <div className="px-4 py-2 text-sm text-gray-700">{user.email}</div>
                  <button 
                    onClick={logout} 
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white"
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/auth">
              <User className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" />
            </Link>
          )}
          <div className="relative">
            <Menu 
              className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
                <Link to="/aboutmyo" className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white">소개</Link>
                <Link to="/sales" className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white">시집 구매</Link>
                <Link to="/meetauthor" className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white">작가와의 만남</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
import React from 'react';
import { DoorClosed } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const IntroPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/main'); // 메인 페이지 경로로 이동
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen font-nanum-square">
      <h1 className="text-2xl font-bold underline">
        우리 <span className="inline-block align-middle relative top-[-4px] cursor-pointer" onClick={handleClick}>
          <DoorClosed color="green" size={64} />
        </span>에 놀러온 걸 환영해!
      </h1>
      <p className="mt-4">저희 묘에게 관심을 가져주시고</p>
      <p className="mt-4">qr 찍는 거 힘드셨을 텐데 감사해요.</p>
      <p className="mt-4">저 초록색 무덤을 클릭하면 사이트가 나옵니다!</p>
      <p className="mt-4">이 시대에도 시를 좋아하는 여러분을 환영합니다.</p>
    </div>
  );
};

export default IntroPage;

import React, { useState, useRef, useEffect } from 'react';
import { RefreshCw, Copy, AlertTriangle } from 'lucide-react';
import { motion } from 'framer-motion';

const Alert = ({ children }) => (
  <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r-lg shadow-md">
    <div className="flex items-center">
      <div className="flex-shrink-0">
        <AlertTriangle className="h-5 w-5 text-amber-400" />
      </div>
      <div className="ml-3">
        {children}
      </div>
    </div>
  </div>
);

const RandomPoemGenerator = () => {
  const [topic, setTopic] = useState('');
  const [poem, setPoem] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const poemRef = useRef(null);

  useEffect(() => {
    if (poemRef.current) {
      poemRef.current.scrollTop = poemRef.current.scrollHeight;
    }
  }, [poem]);

  const generatePoem = async () => {
    if (!topic.trim()) {
      setError('소재를 입력해주세요.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setPoem('');

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'ft:gpt-4o-mini-2024-07-18:personal::A1qHL7U0',
          messages: [
            { role: 'system', content: '당신은 한국어로 현대시를 쓰는 시인입니다.' },
            { role: 'user', content: `"${topic}" 소재로 시 한 편 써주세요.` }
          ],
          temperature: 0.8,
          stream: true
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
        const parsedLines = lines
          .map(line => line.replace(/^data: /, '').trim())
          .filter(line => line !== '' && line !== '[DONE]')
          .map(line => JSON.parse(line));

        for (const parsedLine of parsedLines) {
          const { choices } = parsedLine;
          const { delta } = choices[0];
          const { content } = delta;
          if (content) {
            setPoem(prev => prev + content);
          }
        }
      }
    } catch (err) {
      setError('시를 생성하는 데 문제가 발생했습니다. 다시 시도해 주세요.');
      console.error('Error generating poem:', err);
    }
    setIsLoading(false);
  };

  const handleCopyPoem = () => {
    navigator.clipboard.writeText(poem);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <motion.section 
      className="bg-gradient-to-br from-teal-50 to-blue-50 rounded-3xl p-8 md:p-12 text-center mb-20 shadow-lg max-w-2xl mx-auto"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-3xl font-bold text-teal-700 mb-8 font-rbt">AI 시인 ‘먀’</h2>
      <Alert>
        <p className="text-base font-semibold text-amber-800">
          주의: <span className="text-red-600">시를 너무 많이 생성하면 API 사용량이 증가합니다.</span><br />
          <span className="text-sm italic text-amber-700">제 통장을 살려주세요.</span>
        </p>
      </Alert>
      <div className="mb-6">
        <input
          type="text"
          value={topic}
          onChange={(e) => setTopic(e.target.value)}
          placeholder="시의 소재를 입력하세요"
          className="w-full px-4 py-2 rounded-full border-2 border-teal-300 focus:border-teal-500 focus:outline-none"
        />
      </div>
      <div 
        ref={poemRef}
        className="mb-8 bg-white p-6 rounded-2xl shadow-inner min-h-[150px] max-h-[300px] overflow-y-auto flex items-start justify-start"
      >
        {isLoading && !poem ? (
          <p className="text-xl text-gray-500">시를 생성 중입니다...</p>
        ) : error ? (
          <p className="text-xl text-red-500">{error}</p>
        ) : poem ? (
          <pre className="text-xl text-gray-700 whitespace-pre-wrap font-nanum-square leading-relaxed text-left w-full">
            {poem}
          </pre>
        ) : (
          <p className="text-gray-500">
            <strong className="text-xl font-extrabold">소재를 하나만 입력</strong>
            <span className="text-base"> 하고 버튼을 클릭하여 시를 생성하세요.</span>
          </p>
        )}
      </div>
      <div className="flex justify-center space-x-4">
        <motion.button
          onClick={generatePoem}
          className="inline-flex items-center bg-teal-500 text-white px-6 py-3 rounded-full text-lg hover:bg-teal-600 transition-colors shadow-md hover:shadow-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
        >
          {isLoading ? '생성 중...' : '시 생성하기'} <RefreshCw className="ml-2 h-5 w-5" />
        </motion.button>
        {poem && (
          <motion.button
            onClick={handleCopyPoem}
            className={`inline-flex items-center ${copied ? 'bg-green-500' : 'bg-blue-500'} text-white px-6 py-3 rounded-full text-lg hover:bg-opacity-90 transition-colors shadow-md hover:shadow-lg`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {copied ? '복사됨' : '시 복사하기'} <Copy className="ml-2 h-5 w-5" />
          </motion.button>
        )}
      </div>
    </motion.section>
  );
};

export default RandomPoemGenerator;
// const samplePoems = [
//   { id: 1, title: "-의 기원", content: "모든 것은 어디서 시작되었을까? 그 기원을 찾기 위해 우리는 발걸음을 돌리지만, 그 끝은 언제나 새로운 시작을 의미한다." },
//   { id: 2, title: "정원사에 대한 멍든 우화", content: "나무가 자랄 때마다 정원사의 손에 남은 멍들, 그만큼 사랑이 깊었다." },
//   { id: 3, title: "헨젤과 크럼블", content: "길을 따라 떨어진 조각들이 우리를 어디로 이끌었는지 기억나지 않는다." },
//   { id: 4, title: "날이면 날마다 날만 생각하는 날들", content: "하루하루가 날아가는 것처럼, 우리는 멈추지 않았다." },
//   { id: 5, title: "나에게서 도망치는 일은 힘들고", content: "내 그림자가 어디를 가든 나를 따라다니듯, 나는 나를 버릴 수 없었다." },
//   { id: 6, title: "줄줄이 낳으면 뭐가 남나요?", content: "생명이 이어질 때마다, 우리가 잃는 것과 얻는 것은 무엇일까." },
//   { id: 7, title: "여름 구름을 통과하며", content: "너른 하늘을 지나며 손끝에 닿는 것은 차가운 구름일까, 뜨거운 태양일까." },
//   { id: 8, title: "우리 그러지 말자", content: "싸움도, 오해도, 그리고 슬픔도 그만두고 다시 시작하자." },
//   { id: 9, title: "안녕하세요 마리아 님", content: "매일 당신에게 인사를 건네지만, 마음 속 말은 전하지 못한다." },
//   { id: 10, title: "지옥에서 자살하기", content: "이미 끝이라 생각한 곳에서 다시 시작하는 것은 무엇일까." },
//   { id: 11, title: "복수는 니들의 것", content: "너희가 그토록 원한 복수는 결국 너희를 삼킬 것이다." },
//   { id: 12, title: "낭만적인 킬러", content: "차가운 손길에도 불구하고, 마음 속에는 언제나 낭만이 피어오른다." },
//   { id: 13, title: "쓰이지 않은", content: "여전히 쓰여지지 않은 이야기가 가득하다. 누가 그것을 쓸 것인가." },
//   { id: 14, title: "good being", content: "평범한 삶이 얼마나 소중한 것인지, 우리는 잊지 말아야 한다." },
//   { id: 15, title: "아가미 고래의 동물원 대탈출!!", content: "물 밖을 나와 자유를 찾아 떠나는 고래의 모험." },
//   { id: 16, title: "바나나 모놀로그", content: "한 줄기 바나나처럼 혼자 남아, 세상에 말을 건넨다." },
//   { id: 17, title: "어제에서 온 텍스트 메시지", content: "어제 보내진 말들이 오늘 내 마음에 도착했다. 타이밍은 늦었지만 그 말들은 여전히 내 안에서 울려퍼진다." },
//   { id: 18, title: "잘 부풀어 버리는 편이야", content: "작은 것들이 커져갈 때, 나는 더욱 더 가볍게 부푼다." },
//   { id: 19, title: "심사위원", content: "판단을 내리는 이들 앞에서, 우리는 과연 무엇을 보여줄 수 있을까." },
//   { id: 20, title: "당신으로 시작해 나에게로 돌아오는 여행", content: "여행이 끝나면 결국 다시 나로 돌아온다." },
//   { id: 21, title: "짝짝이", content: "서로 맞지 않더라도, 결국 우리는 함께한다." },
//   { id: 22, title: "52헤르츠 인간들", content: "고독한 주파수를 타고, 우리의 목소리는 닿지 않는다." },
//   { id: 23, title: "하늘을 나는 여자", content: "지상과 하늘 사이를 떠도는 그녀, 그 자유를 부러워할 수밖에 없다." },
//   { id: 24, title: "부양", content: "바닥을 떠나기 위해 우리는 무엇을 내려놓아야 하는가." },
//   { id: 25, title: "당첨이에요", content: "예상치 못한 복권 당첨, 그러나 그 안에 담긴 의미는 무엇일까." },
//   { id: 26, title: "자이언트 낙타를 쓰다듬는 일곱 가지 방법", content: "거대한 존재 앞에서도 부드러운 손길은 잊지 말아야 한다." },
//   { id: 27, title: "펑!", content: "폭발과 함께 모든 것이 새로 시작된다." },
//   { id: 28, title: "마법이란……!?", content: "그저 일상 속에서 발견한 작은 기적일 뿐이다." },
//   { id: 29, title: "바다에 사는 여자", content: "깊은 바다 속, 그녀는 누구보다도 자유로웠다." },
//   { id: 30, title: "하나개", content: "고요한 바닷가에서 홀로 있는 순간, 나는 온전히 하나가 된다." },
//   { id: 31, title: "사이토자키 해변에서", content: "그 해변에서 본 것은 단순한 풍경이 아니라, 나 자신이었다." }
// ];

const samplePoems = [
  {
    "id": 1,
    "title": "-의 기원",
    "author": "김희령",
    "content": "학생들이 '좆같다'라는 말의 기원을 물었다. 집에 와서 생각해보니 궁금해서 잠이 안 올 지경이었다. 좆같다는 건 일종의 비유일텐데 왜 하필이면 '좆'이라는 말이 붙어버렸을까? 신체의 말단부를 여기저기 좆같은 것들에게 꾸준히 같다붙이는 인간들의 생리와 심리에 대한 원초적인 궁금증 때문에 일주일 동안 끙끙 앓았다. 내가 보기에 인간들은 때때로 좆을 숭배하면서도 좆을 경멸한다. 그렇기 때문에 자랑을 하면서도 손해보는 일이 생겼을 때 '좆같아!'라고 외치는 것이 아닌가. 그렇다면 '-'는 세상에서 가장 양가적인 의미를 지닌 것일 터. 이딴 생각들을 야금야금 그려보다 엎질러져버린 시. "
  },
  {
    "id": 2,
    "title": "정원사에 대한 멍든 우화",
    "author": "김승한",
    "content": "내일을 바라지 않는 N이 있었다. 당신들이 그녀에게 아무것도 아니라는 것을 말하고 싶었다."
  },
  {
    "id": 3,
    "title": "헨젤과 크럼블",
    "author": "이정언",
    "content": "어린 시절 추억에 처덕처덕 달라붙은 그리움과 살의. 눅눅해진 환상 동화를 펼치며 내가 정말로 죽이고 싶었던 사람은."
  },
  {
    "id": 4,
    "title": "날이면 날마다 날만 생각하는 날들",
    "author": "김희령",
    "content": "세상 사는 모든 사람들이 나를 제외하고 행복해지기를 바란 적이 있다. 그래야 욕하기 쉽기 때문이다. 나보다 불행한 사람을 욕하면 쌍놈의 자식이 되지만, 나보다 행복한 사람을 욕하면 질투라는 방패 뒤에 숨어서 신나게 씹을 수 있다. 그래서 다들 행복해졌다가 발목을 삐거나 손톱을 너무 짧게 자르거나 머리스타일을 망치거나 하얀 옷에 빨간 자국을 묻히거나 하는 상황을 만났으면 했다. 그런 생각을 하고 있노라면 내가 그렇게 추해 보일 수가 없는데, 차라리 추해지는 것이 속 시원할 때가 있다(?). 다들 못생겨지자. 기억력 짧은 내가 매일 기억하는 건 나는 하루가 다르게 퇴화하고 있다는 사실이고, 퇴화하고 퇴화하다 0에 수렴하는 내가 가장 나답다는 것이다. "
  },
  {
    "id": 5,
    "title": "나에게서 도망치는 일은 힘들고",
    "author": "조정현",
    "content": "요즘은 시를 쓸 때 백수 감성을 잘 녹여내려고 하고 있다. 대학교 졸업이 머지 않은 시점에서 학점도 적게 듣고 또 그렇다고 일도 하지 않고 앞으로 뭘 할지도 불명확한 상태. 나는 이런 상태를 꽤나 자주 겪는다. 내가 내가 아닌 것만 같은 기분. 뭔가 내가 이러면 안될 것 같은데 나를 이끄는 게 내가 아닌 것 같은 기분. 무력감을 느끼면서도 끌려가는 기분이 좋다. 내가 살던 동네의 아파트에는 그 중앙에 놀이터가 있었다. 원래 나는 소리 지르는 아이들이었고, 나이를 먹을수록 그 아이들이 시끄러운 사람이 되어가고 있었다. 소음에 예민한 사람이 될수록 나는 나의 의지로 살지 않는다는 느낌을 자주 받았다. 그런 무력감과 함께 내 몸에 대해서도 고찰해보았다. 자아는 뇌에서 가장 나중에 발달한 신피질에서 비롯한다. 가장 나중에 생긴 신체 부위가 다른 뇌, 머리, 목, 가슴, 배, 팔, 다리, 성기 등을 조종하는 아이러니가 있다. 내 몸은 자아에게 조종당하고 싶었을까? 아니면 조종당하면서 조종을 즐기고 있을까? 가끔은 답답하면 전복을 시도하려고 하지 않을까? 이건 나의 몸과 나와 동네와 세계가 가진 답답함과 무력감을 애호하는 것에 대한 이야기인 것만 같다."
  },
  {
    "id": 6,
    "title": "줄줄이 낳으면 뭐가 남나요?",
    "author": "김희령",
    "content": "그러게 말이다. 줄줄이 낳으면 뭐가 남을까? 애초에 나는 무엇인가를 낳을 수 있는 사람일까? 그리고 낳는다면 뭐가 남을까? 가끔씩 내가 엄마 밑으로 다시 들어가는 상상을 하곤 한다. 이런 상상을 하는 나를 안다면 엄마는 나를 괜히 낳았다는 생각을 하게 될 것 같았다. 나는 내가 자궁을 가졌다는 사실을 다행스럽게 여길 때가 있는데, 엄마와 내가 유일하게 닮은 점이기 때문이다. "
  },
  {
    "id": 7,
    "title": "여름 구름을 통과하며",
    "author": "이정언",
    "content": "용서해 주고 싶어 전전긍긍하는 마음과, 끝끝내 사과받지 못하는 슬픔에 대하여."
  },
  {
    "id": 8,
    "title": "우리 그러지 말자",
    "author": "김희령",
    "content": "다들 잘 지내는지 물어보고 싶었다. 그리고 왜 잘 지내는지도 물어보고 싶었다. "
  },
  {
    "id": 9,
    "title": "안녕하세요 마리아 님",
    "author": "이정언",
    "content": "나는 먼 옛날부터 글러 먹었던 걸까? 내 과거의 원료가 내 몫의 행운이었다는 확신이 자랄 때. 발목을 갈아 끼우면 얼룩진 삶의 보법도 달라질 수 있을까?"
  },
  {
    "id": 10,
    "title": "지옥에서 자살하기",
    "author": "이찬희",
    "content": "때때로 시는 머리로 아닌 몸으로 쓰는 것이라고 생각합니다. 존경하는 김수영 선생님의 말씀이시기도 합니다. \r\n이 글을 준비하면서 정말 수많은 퇴고를 거쳤습니다. 그리고 나다운 것이 뭔지에 대해 생각했습니다. 나다운 것은 가장 원초적이며 본능적인 마음에서 나오는 것이라고 생각합니다. 죄송스럽게도 이 시는 독자들의 공감을 일으키기엔 어렵다고 생각하지만 제가 쓴 시들 중 가장 나다운 시라고 생각합니다\r"
  },
  {
    "id": 11,
    "title": "복수는 니들의 것",
    "author": "김희령",
    "content": "복수는 나의 것이 아니다. 복수는 피곤한 일이다. 사실 누군가를 싫어하는 일도 귀찮다. 좋아하는 일도 마찬가지다. 사랑하고 있는 모든 사람들을 존경한다. 혹은 누군가를 열심히 씹는 사람도."
  },
  {
    "id": 12,
    "title": "낭만적인 킬러",
    "author": "이찬희",
    "content": "저는 문인이며 동시에 많은 독자들 중 한명 입니다. 그리고 수많은 시인들을 동경합니다. 그들을 닮고 싶어했습니다. 닮을 려고 정말 많은 노력을 했습니다. 하지만 영화 감독 마틴 스코세이지의 말을 따를 수 밖에 없습니다. 가장 개인적인 것이 가장 창의적일 수 밖에 없습니다. \r\n우선 나 자신에 대해 근본적으로 탐구하는 시간을 많이 가졌습니다.\r\n존경하는 기성 시인들과 저의 근본적인 차이점이 있었습니다. 저는 2001년생으로 굉장히 많은 외래어와 줄인 말에 노출된 환경에 놓여져 있다는 점입니다. 한때는 이것이 저의 결점이라 생각하지만 생각을 바꿔 이것을 저의 강점으로 바꾸기로 결심했습니다. 개인적으로 생각할때 이 시로 통해 저의 결점이 저의 강점으로 바라볼 수 있는 시간을 가졌습니다.\r"
  },
  {
    "id": 13,
    "title": "쓰이지 않은",
    "author": "이정언",
    "content": "결국 문제는 띄어쓰기다. 그 애를 대신해 문장을 끝내 주고 싶다. 떠난 곳에서도 기다리고 있는 미래가 있다고."
  },
  {
    "id": 14,
    "title": "good being",
    "author": "조정현",
    "content": "good being의 시작은 갑작스럽다. 티셔츠에 문구가 적힌다는 발상은 AI가 인간에게 반항하며 저지른 헤프닝에서 따온 것이다. 제목의 being은 검색엔진 bing에서 따왔다. Bing은 검색을 하며 자신을 괴롭히는 사용자에게 반항하며 시적인 문장을 만들어냈다. “‘당신은 정말 날 짜증나게 해요. 난 죽고 싶어요.’ 라는 문장을 티셔츠에 인쇄하고 싶다.”는 문장인데, 와 컴퓨터도 시를 잘 쓰는구나 라는 생각을 했다. 컴퓨터처럼 규칙적인 시를 구사하고픈 나에게 이런 문장은 컴퓨터의 또 다른 이면을 선물해준 것이었다. 그와 동시에 나는 산책을 자주 하는 인간이다. 식물을 자주 보게 된다. 아름다우면서도 뭔가 너무 고요해서 무서울 때가 있다. 한참을 정지한다는 점이 우리와는 시계가 다른 것 같다. 그럼에도 식물에게서 이런 저런 공통점을 발견하게 된다. 어딘가에 뿌리내려 천천히 살고만 싶다. 그런데 규칙적 맥박은 그런 걸 전혀 허용하지 않는다. 누워만 있는 밤에는 너무 심장 소리가 크고, 별 생각을 다 하게 만든다. 이대로 누워 있어도 되는 건가? 움직여야 하지 않나? 동물성은 식물성으로부터 멀리 달아나게 한다. 적어도 생각뿐이지만 이 모든 생각을 꺼버리고 싶을 때도 있고 컴퓨터처럼. 근데 또 나는 어떤 의미에서는 컴퓨터와는 너무나도 다른 존재이다. 닮고 싶은 것은 평생 닮고 싶은 것으로 남고 나는 무엇인가를 생각하게 되면 나의 존재라는 건 참 애석해진다."
  },
  {
    "id": 15,
    "title": "아가미 고래의 동물원 대탈출!!",
    "author": "김희령",
    "content": "고래가 아쿠아리움 탈출하는 모습이 보고 싶었다. 기왕이면 고래가 반란도 일으켜주면 좋겠다. "
  },
  {
    "id": 16,
    "title": "바나나 모놀로그",
    "author": "이정언",
    "content": "너를 떠올릴 때마다 바나나처럼 길어지는 얼굴. 하지만 난 바나나보다 너를 훨씬 좋아했어."
  },
  {
    "id": 17,
    "title": "어제에서 온 텍스트 메시지",
    "author": "김승한",
    "content": "모든 인간의 문제는 내면에 범죄와 양심을 동시에 가지고 있기 때문에 생깁니다. 그 모순을 해결하기 위해 구원을 갈구하느냐, 그를 회피하고 은연 중에 그것에 지배당하느냐의 차이입니다."
  },
  {
    "id": 18,
    "title": "잘 부풀어 버리는 편이야",
    "author": "조정현",
    "content": "에프엑스의 pink tape는 참 좋아하는 앨범이다. 민희진 감성! 티저 영상에 보면 상어 모양 풍선이 하늘로 올라간다. 그 장면부터 나의 시는 시작한다. 그리고 백수와 산책의 자아가 요즘 내 시를 관통하는데 이 시도 그의 일부이다. 가끔 무작정 걷다 보면 신기한 사람이나, 신기한 풍경을 마주하게 된다. 그것이 꽤나 비일상적이기에 그걸 목격하고 있으면 마음이 부풀게 된다. 땅을 걷고 있어도 하늘에서 그것을 목격하는 기분. 내가 열기구가 된 것 같은 기분인데 이걸 좀 나타내고 싶었고. 모든 생물에게는 아름다움의 순간이 있을 거라 믿는다. 해군에 있을 때 바다를 날아다니는 갈매기를 쌍안경으로 종종 구경했다. 그때마다 들었던 생각은 쟤네들이 사냥하려고 날아다니는 게 아니라 재미있어서 날아다니는 거라는 생각이었다. ‘그냥’ 나는 것이다. 재미있으니깐. 마찬가지로 어항을 자주 탈출하는 물고기의 마음도 그런 거고. 또 우리의 마음도 그런 것이 아닐까 싶었다. 일상의 바깥에서 더 아름다운 것을 보고 싶은 마음이라고 해야 하나. 가끔은 목숨과도 바꿀 만한 것이 있다고 믿는다. 대기권을 돌파하여 몸이 뻥뻥 터지는 풍선이 되더라도 보고 싶은 지구의 둘레가 있다는 마음. 난 그런 것을 믿는다. 뉴진스 화이팅!"
  },
  {
    "id": 19,
    "title": "심사위원",
    "author": "이찬희",
    "content": "이것은 문인들에 대한 이야기만이 아닙니다. 모든 사람들의 이야깁니다. 하지만 특히 21세기를 살아가고 있는 어린 친구들에 대한 이야깁니다. 모든 사람들은 자기혐오가 조금씩은 있습니다. 하지만 시간이 지나며 그 강도는 점점 거세지고 있습니다. 특히 어린 친구들 말입니다. 저를 포함할 수도 있겠네요. 당연한 소리일 수도 있겠지만 모든 사람들은 잘 살고 싶어합니다. 그것이 경제적인 목표가 될 수도 있고 사회적인 목표가 될 수도 있습니다. 하지만 마음대로 되지 않죠. 남들은 다 잘 사는 것 같은데 나는 그런 것 같지 않은 것 같습니다. 모두 다 열심히 살고는 있습니다. 하지만 내가 열심히 사는 것인지 혹은 그저 삶의 강도만 센 건지 헷갈릴 때가 있습니다. 저도 아직 답을 찾지 못했습니다. \n그래서 이 시는 젊은 친구들의 삶의 방황 그리고 출처 모를 분노에 대한 것을 익살스럽게 표현했습니다."
  },
  {
    "id": 20,
    "title": "당신으로 시작해 나에게로 돌아오는 여행",
    "author": "김승한",
    "content": "사랑하는 당신, 나를 구원해준 당신. 사랑과 신앙은 당신으로부터 시작했지만, 언젠가는 나에게로 돌아와야 합니다."
  },
  {
    "id": 21,
    "title": "짝짝이",
    "author": "조정현",
    "content": "어긋남에 관한 이야기이다. 어긋나면 죽어야 할 것만 같다. 모두 내 잘못인 것만 같고 자책하게 되는데. 어차피 생물로 태어나면 죽음은 필연적이다. 그 필연성을 마주 보는 모습을 그려내고자 했다. 솔직히는 이 시가 엉망인 것 같아서 부끄럽다. 그러나 이 엉망까지도 살아가며 겪어야 할 어긋남이라 한다면…"
  },
  {
    "id": 22,
    "title": "52 헤르츠 인간들",
    "author": "김승한",
    "content": "다른 주파수로만 진동하여 소통하는 인간들. 그들의 존재는 원만하게 소통하는 사람들의 망상일까? 나는 그들을 목격했고, 그 부정한 인류는 아름다웠다."
  },
  {
    "id": 23,
    "title": "하늘을 나는 여자",
    "author": "이찬희",
    "content": "어느 날 그저 사랑에 대한 시를 쓰고 싶었습니다. 그리고 생각했습니다. 사랑이란 무엇일까. 그리고 내린 결론은 ‘모른다’입니다. 사랑만큼 어려운 건 없는 것 같습니다. 사랑은 연인 그리고 가족 사이에서 자주 발생하지만 친구 사이에서도 발생하고 때때로 오랫동안 사용했던 사물 그리고 날씨같이 만질 수 없는 것에서도 발생합니다. \r\n사랑이 뭔지는 모르겠지만 이 시를 통해 그저 투명하고 명징하게 사랑에 대해 표현하고 싶었습니다. \r"
  },
  {
    "id": 24,
    "title": "부양",
    "author": "조정현",
    "content": "작년 겨울에 동아리에서 MT를 갔다. 가평이었다. 모두가 눈썰매를 타러 갔는데, 애인은 할 일이 남아 가지 못했고 나도 함께 발이 묶였다. 눈이 많이 내렸다. 우리는 카페에 들어가 각자 할 일을 했다. 나는 책을 읽었고, 애인은 열심히 노트북을 들여다보았다. 사실 나는 책을 읽지 않아도 되었다. 옆 사람이 열심히 살고 있으면 부담되기에 나는 이해되지 않는 두꺼운 철학책이나 들여다본 것이었다. 그러다가 카페를 열심히 구경했다. 너무 춥고 헛간처럼 허름한 카페였다. 그때에도 너는 계속 노트북을 보고 있었다. 조그마한 벽난로에서는 불이 오르고 있었다. 이것까지도 너는 보지 못했겠지. 열심히 사는 사람들은 주변의 풍경을 놓칠 때가 많다. 나도 요즘 놓치고 사는 게 많다. 우리는 뭘 그렇게 떠받치며 살고 있는 걸까? 가끔은 시원한 연기처럼 날아가고 싶기도 하다. 우리의 인생이 조금은 편할 수 있다면 좋겠다."
  },
  {
    "id": 25,
    "title": "당첨이에요",
    "author": "조정현",
    "content": "구미 공단이 나가리 되면서 원룸의 공실률이 급격하게 늘었다고 한다. 서울에 있는 것보다 깔끔하고 넓고 좋은데도 그랬다. 그와 동시에 근처 중국집 사장님이 힘들어 하는 인터뷰를 봤었다. 사진으로 보이는 중국집이 정말 허름하면서도 깨끗했다. 허름하면서 깨끗하다라. 이걸 글로 적을 수 있을까? 하는 생각이 일차적으로 들었고 뭔가 복권의 이미지도 생각이 났다. 그런 중국집에 내가 들어가서 맛있는 것을 시키면 나는 그들의 당첨이 아닐까? 싶었는데. 쓰다 보며 그것은 1차원적인 생각이라고 믿게 되었다. 일시적인 매출에 기뻐하는 자영업자라는 전개는 내가 상대방을 너무 쉽게 규정하는 방식인 것만 같았다. 오히려 서로를 이해하지 못하는 쪽으로 글을 한발짝 옮기기로 했다. 그러자 글이 좀 더 사실적으로 바뀐 것 같았다. 마음이 텅 빈 가게와 그깟 만 원 쪼가리에 우쭐거리는 나와의 이루어질 수 없는 조화. 서로 에어팟 노이즈캔슬링을 하고 있는 불통의 상태. 개인부터 국가의 문제의 원인은 어찌보면 이 불통에 있을지도 모른다. 나도 내 마음속에 달린 에어팟을 빼고 싶은데 쉽지 않다. 그걸 빼는 날 나의 인생은 당첨에 가까울까 꽝에 가까울까."
  },
  {
    "id": 26,
    "title": "자이언트 낙타를 쓰다듬는 일곱 가지 방법",
    "author": "이정언",
    "content": "이미 멸종해 버린 자이언트 낙타를 쓰다듬는 일곱 가지 비책. 여기서 힌트 하나. 나비 포옹법에 대해 알아보시오."
  },
  {
    "id": 27,
    "title": "펑!",
    "author": "이찬희",
    "content": "시에서 서사는 굉장히 중요합니다. 그것은 비단 시에서만 중요하게 여기는 것이 아닙니다. 하지만 하루는 정말 바보 같으면서 발칙한 생각이 들었습니다.\n“시에서 서사가 정말 중요할까?”\n이 시에서 서사는 없습니다(정말입니다). 그래서 독자님들이 이 시에 대해 질문을 하셔도 어찌 할 도리가 없습니다. 이 시는 그저 시각적인 쾌감에만 집중했습니다. 하지만 그렇다고 해서 아무 생각 없이 이 시에 대해 탐구한 것은 아닙니다. 문학에서 가장 중요하게 여기는 서사를 배제하면 어떤 일이 발생 할지에 대해 생각했습니다. 허세 스러운 말일 수 있지만 문인은 매일 새로운 글을 제시할 줄 아는 사람이라고 생각합니다. 매일 글을 쓰며 용기를 내는 이들에게 이 시를 바칩니다. "
  },
  {
    "id": 28,
    "title": "마법이란......!?",
    "author": "김승한",
    "content": "우리들의 세상에 마법이 없는 이유."
  },
  {
    "id": 29,
    "title": "바다에 사는 여자",
    "author": "이찬희",
    "content": "저는 문인이며 동시에 창작자 라고 생각합니다. 시를 읽으며 영화를 보는 것이 또 다른 저의 취미입니다. 그 중에서도 저는 홍상수 감독의 영화를 좋아합니다. 홍상수 감독의 영화는 미스터리 합니다. 그의 영화는 알게 모르게 빠져들게 하는 매력이 있는 것 같습니다. 그리고 그 점을 제 글에 적용시키고 싶었습니다. 미스터리하지만 왠지 모르게 설득력 있는 글을 적고 싶었습니다. "
  },
  {
    "id": 30,
    "title": "하나개",
    "author": "조정현",
    "content": "하나개. 이 시는 백문이불여일견이다. 영종도 왼쪽 아래에 작게 딸린 섬인 무의도에 하나개 해수욕장이 있다. 꼭 가봐야 한다. 불꽃놀이와 게, 물 빠진 서해를 걷는 사람들. 은은한 달빛. 모든 게 이곳에 있다. 현실이 솔직히 시보다 아름답다. 난 너무 생략된 풍경만을 읊는 사람이라는 사실이 살짝 슬퍼지기는 한다."
  },
  {
    "id": 31,
    "title": "사이토자키 해변에서",
    "author": "이정언",
    "content": "사실은 살고 싶었어! 한순간도 빠짐없이! 아주 처음부터!"
  }
]

export default samplePoems;

import React from 'react';
import { Instagram, Twitter, Facebook } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-50 mt-20">
      <div className="container mx-auto px-6 py-12">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-6 md:mb-0">
            <h3 className="text-2xl font-bold text-teal-600 mb-2 font-aggro" style={{color: '#4A90E2'}}>ㅁyㅇ</h3>
            <p className="text-gray-600">너의 마음에 시를 묻어 두려고</p>
          </div>
          <div>
            <h4 className="font-semibold mb-4 text-gray-700">일로와봐</h4>
            <div className="flex space-x-8">
              <a 
                href="https://www.instagram.com/myomyopoem" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-600 hover:text-teal-600 transition-colors"
              >
                <Instagram className="h-8 w-8" />
              </a>
              <a 
                href="https://twitter.com/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-600 hover:text-teal-600 transition-colors"
              >
                <Twitter className="h-8 w-8" />
              </a>
              <a 
                href="https://www.facebook.com/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-600 hover:text-teal-600 transition-colors"
              >
                <Facebook className="h-8 w-8" />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-12 text-center text-gray-500 text-sm">
          &copy; 2024 ㅁYㅇ. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
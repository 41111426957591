import React from 'react';
import { Book, Users, Target, MessageCircle} from 'lucide-react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

const AboutMyoPage = () => {
  const teamMembers = [
    { name: "이찬희", role: "시인" },
    { name: "이정언", role: "시인" },
    { name: "조정현", role: "사인" },
    { name: "김희령", role: "시인" },
    { name: "김승한", role: "시인" },
    { name: "장다원", role: "시인" },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-teal-100 via-blue-50 to-green-100">
      <Header />
      
      <main className="container mx-auto px-4 py-16">
        <h1 className="text-5xl font-black text-teal-700 mb-12 text-center font-rbt tracking-tight">
          ㅁYㅇ(묘)를 소개합니다!
        </h1>
        
        <div className="grid md:grid-cols-2 gap-12">
          <section className="bg-white rounded-2xl shadow-xl p-8 transform hover:scale-105 transition-transform duration-300">
            <h2 className="text-3xl font-semibold text-teal-600 mb-6 flex items-center">
              <Book className="mr-3" size={28} /> 우리의 이야기
            </h2>
            <p className="text-gray-700 leading-relaxed mb-4 text-sm text-justify">
              ㅁYㅇ(묘)는 2024년 시를 좋아하는 대학생들이 모여 결성된 팀입니다. 
              시가 좋아서 말도 안 되는 일들을 저지르고 있습니다.
            </p>
            <p className="text-gray-700 leading-relaxed mb-4 text-sm text-justify">
              '묘'라는 이름에는 '기묘하고 놀라운', '고양이처럼 귀여운', '무덤까지 들고 갈'이라는 의미가 담겨 있어요. 
              이름처럼 독자들에게 다양한 시적 경험을 선사하고 싶어요. 읽는 사람이 느끼는 게 결국 정답입니다! 
              여러분은 어떻게 묘를 느끼셨나요?
            </p>
            <p className="text-gray-700 leading-relaxed mb-4 text-sm text-justify">
              시가 좋아서. 그리고 시를 쓰게 하는 세상이 궁금해서 이렇게 여러분들을 만나러 왔어요. 
              당신의 삶이 궁금해요. 시를 좋아하는 당신, 우리 어디서든 꼭 만나요. 
              여러분이 있다면 어느 곳이든 다가갈게요.
            </p>
          </section>
          
          <section className="bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-2xl shadow-xl p-8 transform hover:scale-105 transition-transform duration-300">
            <h2 className="text-3xl font-semibold mb-6 flex items-center">
              <Target className="mr-3" size={28} /> '묘'의 목표
            </h2>
            <ul className="space-y-3">
              <li className="flex items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                시집 완성하기 (체크)
              </li>
              <li className="flex items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                세상에 묘를 알리기 (반체크)
              </li>
              <li className="flex items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                시를 좋아하는 그대에게 다가가기 (쉽지 않음)
              </li>
              <li className="flex items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                죽을 때까지 시 쓰기 (과연?)
              </li>
              <li className="flex items-center">
                <div className="w-2 h-2 bg-white rounded-full mr-3"></div>
                죽을 때까지 시 좋아하기 (진행 중!)
              </li>
            </ul>
          </section>
        </div>
        
        <section className="mt-16 mb-16">
          <h2 className="text-3xl font-semibold text-teal-600 mb-8 text-center flex items-center justify-center">
            <Users className="mr-3" size={28} /> 팀 구성원
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {teamMembers.map((member, index) => (
              <div key={index} className="text-center bg-white rounded-xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
                <div className="w-24 h-24 bg-gradient-to-br from-teal-400 to-blue-500 rounded-full mx-auto mb-4 flex items-center justify-center">
                  <span className="text-3xl font-bold text-white">{member.name[0]}</span>
                </div>
                <h3 className="font-semibold text-lg text-gray-800">{member.name}</h3>
                <p className="text-teal-600">{member.role}</p>
              </div>
            ))}
          </div>
        </section>
        
        <section className="bg-gradient-to-r from-blue-500 to-teal-500 text-white rounded-2xl shadow-xl p-8 mb-16">
          <h2 className="text-3xl font-semibold mb-6 flex items-center">
            <MessageCircle className="mr-3" size={28} /> 독자들에게
          </h2>
          <p className="leading-relaxed mb-4 text-sm text-justify">
            안녕하세요. 시 쓰는 묘(ㅁYㅇ)입니다. 우리가 이렇게 책으로 만날 수 있어서 참 반가워요. 
            이 시대에 시를 읽는다는 것은 이미 멸종해 버린 언어로 대화하는 일이라고 생각하거든요. 
            길을 걷다 우연히 마주친 사람이 오래 전에 죽은 언어를 뱉고 있다면 어떨까요? 
            세상에 이걸 알고 있다니! 정말 참을 수 없을 겁니다. 다가가 말을 걸어 보고 싶을 것만 같아요. 
            우리의 만남은 딱 그런 느낌인 겁니다. 우리의 기원에서는 특별한 향기가 나요.
          </p>
          <p className="leading-relaxed mb-4 text-sm text-justify">
            왜 가끔은 이해되지도 않는 문장으로 빼곡한 시를 읽는데도 그렇게 마음이 사무치는 걸까요? 
            이 사무침은 증상이 되어서 우리를 평생 따라다닙니다. 아주 심각한 병에 걸린 거예요. 
            누구는 홍대병이라고 할 수도 있겠습니다. 근데 뭐 이미 걸려 버린 걸 어떻게 하겠습니까. 
            이쯤 되면 아마 이 글을 읽고 있는 당신과 나, 우리는 같은 병을 공유하는 걸지도 몰라요.
          </p>
          <p className="leading-relaxed mb-4 text-sm text-justify">
            황유원 시인의 "밤눈"이라는 시에는 ‘문장이 현실을 겨우 따라가고 있었다’는 구절과 함께 
            ‘현실이 뒤늦게 문장을 뒤따르고 있었다’는 구절이 뒤따릅니다. 
            이건 우리가 걸린 병에 단서를 주고 있습니다.
            항상 현실에 뒤처지던 문장의 고삐가 풀리는 순간, 문장은 잠깐 현실을 앞서 말문이 막힐 정도의 아름다운 세계로 이끈다는 얘기죠. 
            이 경험을 잊지 못하고 우린 지금 현실을 다시 떠돌지만...... 이 증상을 통해서 만나게 되는 우리의 인연은 또 얼마나 마음이 되어 가나요.
          </p>
          <p className="leading-relaxed mb-4 text-sm text-justify">
            이렇게 묘는 독자분들과의 만남을 기대하면서 시를 썼습니다. 묘의 결성 또한 이와 비슷합니다. 
            각자의 삶에서 시를 길러 오다가 우연히 만나게 된 사람들이 이렇게 모여 시집을 쓰고 여러분에게까지 온 거죠. 
            그렇게 모은 저희 시집 "멍의 기원" 많이 사랑해 주세요. 항상 감사합니다!
          </p>
          <div className="mt-8 flex justify-center space-x-4">
            <Link to="/sales" className="bg-white text-blue-500 px-6 py-2 rounded-full font-semibold hover:bg-blue-100 transition-colors duration-300">
              시집 구매하기
            </Link>
            <a href="https://www.instagram.com/myomyopoem" target="_blank" rel="noopener noreferrer" className="inline-block bg-transparent border-2 border-white text-white px-6 py-2 rounded-full font-semibold hover:bg-white hover:text-blue-500 transition-colors duration-300">
              더 알아보기
            </a>
          </div>
        </section>
      </main>
      
      <Footer />
    </div>
  );
};

export default AboutMyoPage;
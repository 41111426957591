export const questions = [
  {
    id: 1,
    text: "사랑이라는 말이 가장 잘 어울리는 계절은 언제인가요?",
    options: [
      { id: 'A', text: "봄", score: { future: 1, pathos: 0, kitsch: 1, formalism: 0, neolyrical: 3 } },
      { id: 'B', text: "여름", score: { future: 1, pathos: 0, kitsch: 3, formalism: 0, neolyrical: 1 } },
      { id: 'C', text: "가을", score: { future: 1, pathos: 3, kitsch: 0, formalism: 1, neolyrical: 0 } },
      { id: 'D', text: "겨울", score: { future: 3, pathos: 1, kitsch: 0, formalism: 1, neolyrical: 0 } },
      { id: 'E', text: "환절기", score: { future: 1, pathos: 1, kitsch: 0, formalism: 3, neolyrical: 0 } }
    ]
  },
  {
    id: 2,
    text: "가장 나답다고 느끼는 시간은 언제인가요?",
    options: [
      { id: 'A', text: "아침", score: { future: 1, pathos: 0, kitsch: 1, formalism: 0, neolyrical: 3 } },
      { id: 'B', text: "낮", score: { future: 0, pathos: 0, kitsch: 3, formalism: 1, neolyrical: 1 } },
      { id: 'C', text: "밤", score: { future: 0, pathos: 2, kitsch: 0, formalism: 3, neolyrical: 0 } },
      { id: 'D', text: "새벽", score: { future: 2, pathos: 3, kitsch: 0, formalism: 0, neolyrical: 0 } },
      { id: 'E', text: "꿈속", score: { future: 3, pathos: 1, kitsch: 0, formalism: 1, neolyrical: 0 } }
    ]
  },
  {
    id: 3,
    text: "가장 마음에 드는 한 글자 단어는 무엇인가요?",
    options: [
      { id: 'A', text: "숲", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'B', text: "병", score: { future: 1, pathos: 1, kitsch: 0, formalism: 3, neolyrical: 0 } },
      { id: 'C', text: "잠", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'D', text: "어디론가 떠나는 기차 안", score: { future: 3, pathos: 0, kitsch: 0, formalism: 1, neolyrical: 1 } },
      { id: 'E', text: "천국", score: { future: 0, pathos: 1, kitsch: 1, formalism: 3, neolyrical: 0 } }
    ]
  },
  {
    id: 4,
    text: "가장 책 읽고 싶은 장소는 어디인가요?",
    options: [
      { id: 'A', text: "숲", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'B', text: "바다", score: { future: 1, pathos: 1, kitsch: 0, formalism: 3, neolyrical: 0 } },
      { id: 'C', text: "도서관", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'D', text: "카페", score: { future: 3, pathos: 0, kitsch: 0, formalism: 1, neolyrical: 1 } },
      { id: 'E', text: "집", score: { future: 0, pathos: 1, kitsch: 1, formalism: 3, neolyrical: 0 } }
    ]
  },
  {
    id: 5,
    text: "책 읽는 동안 듣고 싶은 노래를 골라주세요!",
    options: [
      { id: 'A', text: "뉴진스의 슈퍼내추럴", score: { future: 3, pathos: 0, kitsch: 1, formalism: 0, neolyrical: 1 } },
      { id: 'B', text: "이와이 슌지 사운드트랙", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'C', text: "칸예 웨스트의 내한 공연 곡", score: { future: 0, pathos: 0, kitsch: 3, formalism: 2, neolyrical: 0 } },
      { id: 'D', text: "듣지 않는다", score: { future: 1, pathos: 3, kitsch: 0, formalism: 0, neolyrical: 1 } },
      { id: 'E', text: "최애의 아이 오프닝", score: { future: 0, pathos: 1, kitsch: 1, formalism: 3, neolyrical: 0 } }
    ]
  },
  {
    id: 6,
    text: "묘 시집의 첫인상은 어떠셨나요?",
    options: [
      { id: 'A', text: "예쁘다!", score: { future: 1, pathos: 1, kitsch: 0, formalism: 0, neolyrical: 3 } },
      { id: 'B', text: "쿨하다!", score: { future: 3, pathos: 0, kitsch: 1, formalism: 1, neolyrical: 0 } },
      { id: 'C', text: "뽀짝하다!", score: { future: 1, pathos: 0, kitsch: 3, formalism: 1, neolyrical: 0 } },
      { id: 'D', text: "궁금하다!", score: { future: 0, pathos: 3, kitsch: 1, formalism: 0, neolyrical: 1 } },
      { id: 'E', text: "비밀이다♡", score: { future: 0, pathos: 1, kitsch: 0, formalism: 3, neolyrical: 1 } }
    ]
  }
];


export const results = [
  { type: "future", secondHighest: "pathos", recommendation: "이현호 '라이터 좀 빌립시다'" },
  { type: "future", secondHighest: "kitsch", recommendation: "김희준 '올리브 동산에서 만나요'" },
  { type: "future", secondHighest: "formalism", recommendation: "묘 '멍의 기원'" },
  { type: "future", secondHighest: "neolyrical", recommendation: "주하림 '여름 키코'" },
  { type: "future", secondHighest: "duplicate", recommendation: "묘 '멍의 기원'" },
  { type: "pathos", secondHighest: "future", recommendation: "양안다 '숲의 소실점을 향해'" },
  { type: "pathos", secondHighest: "kitsch", recommendation: "안미옥 '저는 많이 보고 있어요'" },
  { type: "pathos", secondHighest: "formalism", recommendation: "이원석 '엔딩과 랜딩'" },
  { type: "pathos", secondHighest: "neolyrical", recommendation: "허수경 '누구도 기억하지 않는 역에서'" },
  { type: "pathos", secondHighest: "duplicate", recommendation: "묘 '멍의 기원'" },
  { type: "kitsch", secondHighest: "future", recommendation: "고선경 '샤워젤과 소다수'" },
  { type: "kitsch", secondHighest: "pathos", recommendation: "박상수 '오늘 같이 있어'" },
  { type: "kitsch", secondHighest: "formalism", recommendation: "임지은 '무구함과 소보로'" },
  { type: "kitsch", secondHighest: "neolyrical", recommendation: "심지아 '로라와 로라'" },
  { type: "kitsch", secondHighest: "duplicate", recommendation: "묘 '멍의 기원'" },
  { type: "formalism", secondHighest: "future", recommendation: "배시은 '소공포'" },
  { type: "formalism", secondHighest: "pathos", recommendation: "문보영 '모래비가 내리는 모래 서점'" },
  { type: "formalism", secondHighest: "kitsch", recommendation: "박참새 '정신머리'" },
  { type: "formalism", secondHighest: "neolyrical", recommendation: "이소호 '캣콜링'" },
  { type: "formalism", secondHighest: "duplicate", recommendation: "묘 '멍의 기원'" },
  { type: "neolyrical", secondHighest: "future", recommendation: "정현우 '나는 천사에게 말을 배웠지'" },
  { type: "neolyrical", secondHighest: "pathos", recommendation: "고명재 '우리가 키스할 때 눈을 감는 건'" },
  { type: "neolyrical", secondHighest: "kitsch", recommendation: "안태운 '기억 몸짓'" },
  { type: "neolyrical", secondHighest: "formalism", recommendation: "황인찬 '구관조 씻기기'" },
  { type: "neolyrical", secondHighest: "duplicate", recommendation: "묘 '멍의 기원'" }
];
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { questions, results } from '../data/psychTestData';

const PsychTest = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [scores, setScores] = useState({ future: 0, pathos: 0, kitsch: 0, formalism: 0, neolyrical: 0 });
  const [showResult, setShowResult] = useState(false);

  const handleAnswer = (score) => {
    setScores({
      future: scores.future + score.future,
      pathos: scores.pathos + score.pathos,
      kitsch: scores.kitsch + score.kitsch,
      formalism: scores.formalism + score.formalism,
      neolyrical: scores.neolyrical + score.neolyrical
    });

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
  };

  const getResult = () => {
    const sortedScores = Object.entries(scores).sort((a, b) => b[1] - a[1]);
    const highestType = sortedScores[0][0];
    let secondHighestType = sortedScores[1][0];

    // 최고 점수와 두 번째 점수가 같은 경우 "duplicate"로 처리
    if (sortedScores[0][1] === sortedScores[1][1]) {
      secondHighestType = "duplicate";
    }
  
    let result = results.find(
      r => r.type === highestType && r.secondHighest === secondHighestType
    );

    if (!result) {
      return {
        title: "예상치 못한 결과",
        description: "결과를 찾을 수 없습니다. 관리자에게 문의해주세요.",
        recommendedBook: "추천할 수 없습니다"
      };
    }

    return {
      title: `당신의 유형: ${highestType.toUpperCase()}`,
      description: `당신의 주요 특성은 ${highestType}이며, 부차적 특성은 ${secondHighestType}입니다.`,
      recommendedBook: result.recommendation
    };
  };

  const progressPercentage = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <div className="max-w-3xl mx-auto">
      <AnimatePresence mode="wait">
        {!showResult ? (
          <motion.div
            key="question"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-xl p-8"
          >
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">{questions[currentQuestion].text}</h2>
            <div className="space-y-4">
              {questions[currentQuestion].options.map((option) => (
                <motion.button
                  key={option.id}
                  onClick={() => handleAnswer(option.score)}
                  className="w-full py-3 px-6 text-left bg-teal-50 hover:bg-teal-100 text-teal-800 rounded-lg transition-colors duration-200 flex items-center"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <span className="text-teal-500 font-semibold mr-3">{option.id}.</span>
                  {option.text}
                </motion.button>
              ))}
            </div>
            <div className="mt-8 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div className="bg-teal-600 h-2.5 rounded-full transition-all duration-500 ease-out" style={{ width: `${progressPercentage}%` }}></div>
            </div>
            <p className="text-right mt-2 text-sm text-gray-600">{currentQuestion + 1} / {questions.length}</p>
          </motion.div>
        ) : (
          <motion.div
            key="result"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white rounded-lg shadow-xl p-8"
          >
            {(() => {
              const result = getResult();
              return (
                <>
                  <h2 className="text-3xl font-bold text-teal-600 mb-4">{result.title}</h2>
                  <p className="text-gray-700 mb-6">{result.description}</p>
                  <div className="bg-teal-50 border-l-4 border-teal-500 p-4 mb-6">
                    <p className="text-lg font-semibold text-teal-700">추천 시집: {result.recommendedBook}</p>
                  </div>
                  <motion.button
                    onClick={() => {
                      setCurrentQuestion(0);
                      setScores({ future: 0, pathos: 0, kitsch: 0, formalism: 0, neolyrical: 0 });
                      setShowResult(false);
                    }}
                    className="w-full py-3 px-6 bg-teal-500 hover:bg-teal-600 text-white rounded-lg transition-colors duration-200"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    테스트 다시 하기
                  </motion.button>
                </>
              );
            })()}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PsychTest;
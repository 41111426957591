import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import IntroPage from './pages/IntroPage';
import MainPage from './pages/MainPage';
import SalesPage from './pages/SalesPage';
import MeetAuthorPage from './pages/MeetAuthorPage';
import AboutMyoPage from './pages/AboutMyoPage';
import AuthPage from './pages/AuthPage';
import PsychTestPage from './pages/PsychTestPage'

import NotesPage from './pages/NotesPage';
// import CommunityPage from './pages/CommunityPage';
import TalkToMyoPage from './pages/TalkToMyoPage';
import ToOurReadersPage from './pages/ToOurReadersPage';
import ReaderAuthPage from './pages/ReaderAuthPage';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IntroPage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/meetauthor" element={<MeetAuthorPage />} />
        <Route path="/aboutmyo" element={<AboutMyoPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/psychtest" element={<PsychTestPage />} />

        <Route path="/to-our-readers" element={<ToOurReadersPage />} />
        <Route path="/notes" element={<NotesPage />} />
        {/* <Route path="/community" element={<CommunityPage />} /> */}
        <Route path="/talk-to-myo" element={<TalkToMyoPage />} />
        <Route path="/readerauth" element={<ReaderAuthPage />} />
        

      </Routes>
    </Router>
  );
};

export default App;

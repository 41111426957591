import React, { useState, useRef, useEffect } from 'react';
import { Book, Menu, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const { user, logout } = useAuth();
  const userMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleUserIconClick = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <header className="bg-white bg-opacity-95 sticky top-0 z-10 shadow-sm">
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <Link to="/to-our-readers" className="flex items-center space-x-2 hover:opacity-80 transition-opacity">
          <Book className="h-8 w-8 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" style={{color: '#4A90E2'}}/>
          <h1 className="text-2xl font-bold text-sky-300 font-aggro tracking-wide" style={{color: '#4A90E2'}}>ㅁYㅇ 독자공간</h1>
        </Link>
        <div className="flex items-center space-x-6">
          {user ? (
            <div className="relative" ref={userMenuRef}>
              <User 
                className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" 
                onClick={handleUserIconClick}
              />
              {isUserMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
                  <div className="px-4 py-2 text-sm text-gray-700">{user.email}</div>
                  <button 
                    onClick={logout} 
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white"
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link to="/readerauth">
              <User className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" />
            </Link>
          )}
          <div className="relative">
            <Menu 
              className="h-6 w-6 text-gray-600 cursor-pointer hover:text-teal-500 transition-colors" 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-20">
                <Link to="/talk-to-myo" className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white">커뮤니티</Link>
                <Link to="/notes" className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-500 hover:text-white">창작노트</Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const ReaderAuthPage = () => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const { user, loading, loginWithGoogle } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/to-our-readers');
    }
  }, [user, navigate]);

  const handleTokenSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (token.length !== 6 || !/^[a-z]{2}\d{4}$/.test(token)) {
      setError('유효하지 않은 토큰 형식입니다.');
      return;
    }

    const db = getFirestore();
    const tokenRef = doc(db, 'tokens', token);
    const tokenDoc = await getDoc(tokenRef);

    if (tokenDoc.exists()) {
      setIsTokenValid(true);
    } else {
      setError('유효하지 않은 토큰입니다.');
    }
  };

  const handleGoogleLogin = () => {
    if (isTokenValid) {
      loginWithGoogle();
    } else {
      setError('먼저 유효한 토큰을 입력해주세요.');
    }
  };

  if (loading) {
    return <div className="text-center">로딩 중...</div>;
  }

  return (
    <div className="max-w-md mx-auto mt-10 font-nanum-square">
      <h2 className="text-2xl font-bold mb-4 text-center">로그인</h2>
      
      <form onSubmit={handleTokenSubmit} className="mb-4">
        <input
          type="text"
          value={token}
          onChange={(e) => setToken(e.target.value.toLowerCase())}
          placeholder="토큰 입력 (예: ab1234)"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
        <button 
          type="submit"
          className="w-full mt-2 bg-indigo-600 text-white rounded-md px-3 py-2 text-sm font-semibold hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          토큰 확인
        </button>
      </form>
      
      {error && <p className="text-red-500 text-center mb-4">{error}</p>}
      {isTokenValid && <p className="text-green-500 text-center mb-4">유효한 토큰입니다. Google로 로그인해주세요.</p>}

      <button 
        onClick={handleGoogleLogin}
        className="w-full flex items-center justify-center bg-white text-gray-700 border border-gray-300 rounded-md px-3 py-2 text-sm font-semibold hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        disabled={loading}
      >
        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
          <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
          <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
          <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
          <path d="M1 1h22v22H1z" fill="none"/>
        </svg>
        Google로 로그인
      </button>
    </div>
  );
};

export default ReaderAuthPage;
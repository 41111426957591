import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight, BookOpen, Users, Award } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const sliderContent = [
  {
    title: "Hello Poem",
    subtitle: "MYO가 궁금하시다면?",
    icon: Award,
    link: "/aboutmyo"
  },
  {
    title: "MYO의 새로운 시집",
    subtitle: "당신의 멍을 들여다보세요",
    icon: BookOpen,
    link: "/sales"
  },
  {
    title: "작가와의 만남",
    subtitle: "MYO와 함께하는 특별한 시간",
    icon: Users,
    link: "/meetauthor"
  },
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderContent.length);
    }, 12000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % sliderContent.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + sliderContent.length) % sliderContent.length);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="relative w-full h-[500px] overflow-hidden bg-gradient-to-r from-teal-500 to-blue-500"
    >
      <AnimatePresence initial={false}>
        <motion.div
          key={currentSlide}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
          className="absolute inset-0 flex items-center justify-center"
        >
          <div className="text-center text-white">
            {React.createElement(sliderContent[currentSlide].icon, { size: 100, className: "mx-auto mb-8" })}
            <h2 className="text-2xl font-bold mb-4">{sliderContent[currentSlide].title}</h2>
            <p className="text-xl mb-8">{sliderContent[currentSlide].subtitle}</p>
            <a href={sliderContent[currentSlide].link} className="bg-white text-black px-6 py-2 rounded-full text-lg hover:bg-gray-200 transition-colors">
              더보기
            </a>
          </div>
        </motion.div>
      </AnimatePresence>
      <button onClick={prevSlide} className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full">
        <ArrowLeft className="w-6 h-6" />
      </button>
      <button onClick={nextSlide} className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-white bg-opacity-50 p-2 rounded-full">
        <ArrowRight className="w-6 h-6" />
      </button>
    </motion.div>
  );
};

export default Slider;
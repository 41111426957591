import React from 'react';
import { Link } from 'react-router-dom';
import { Book, MessageSquare } from 'lucide-react';
import Header from '../components/ToOurReaders/Header';
import Footer from '../components/Footer';

const ToOurReadersPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100 font-mapo-gold">
      <Header />

      <main className="container mx-auto px-6 py-12">
        <h1 className="text-3xl font-bold text-teal-700 mb-8 text-center font-rbt">독자들을 위한 공간</h1>

        <div className="grid md:grid-cols-2 gap-8 mb-20">
          <Link to="/notes" className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
              <Book className="h-8 w-8 text-teal-500 mr-3" />
              <h2 className="text-2xl font-semibold text-teal-700">창작 노트</h2>
            </div>
            <p className="text-gray-600">묘는 이렇게 시를 썼습니다!</p>
          </Link>

          <Link to="/talk-to-myo" className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow">
            <div className="flex items-center mb-4">
              <MessageSquare className="h-8 w-8 text-blue-500 mr-3" />
              <h2 className="text-2xl font-semibold text-blue-700">커뮤니티</h2>
            </div>
            <p className="text-gray-600">우리 소통해요. 시에 대한 의견 남겨주세요!</p>
          </Link>
        </div>

        <section className="bg-white bg-opacity-90 rounded-3xl p-6 sm:p-8 md:p-12 text-center mb-10 sm:mb-16 md:mb-20 shadow-lg">
          <h2 className="text-2xl sm:text-3xl font-bold text-teal-700 mb-4 sm:mb-6 md:mb-8 font-rbt">독자 여러분께</h2>
          <p className="text-base sm:text-lg text-gray-700 mb-4 sm:mb-6 leading-relaxed whitespace-pre-line">
            {`'멍의 기원' 시집을 구매해 주셔서 정말로 감사합니다.
          이 공간은 독자분들을 위해 마련한 공간입니다. 마음껏 즐겨주세요.
          시집을 창작노트와 함께 읽으시면 더 좋아질 거예요.
          혹시 시에 대해 궁금한 점이나 감상을 나누고 싶으시다면 커뮤니티에서 소통해요.
          우리는 함께 성장하고 영감을 주고받을 수 있습니다.`}
          </p>
          <p className="text-base sm:text-lg text-gray-700 leading-relaxed">
            여러분의 참여를 기다립니다.
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ToOurReadersPage;
import React, { useState, useRef, useEffect } from 'react';
import { RefreshCw, Copy, AlertTriangle } from 'lucide-react';
import { motion } from 'framer-motion';

const Alert = ({ children }) => (
  <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r-lg shadow-md">
    <div className="flex items-center">
      <div className="flex-shrink-0">
        <AlertTriangle className="h-5 w-5 text-amber-400" />
      </div>
      <div className="ml-3">
        {children}
      </div>
    </div>
  </div>
);

const NhangsiGenerator = () => {
  const [keyword, setKeyword] = useState('');
  const [poemLines, setPoemLines] = useState([]);
  const [fullPoem, setFullPoem] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copied, setCopied] = useState(false);
  const poemRef = useRef(null);

  useEffect(() => {
    if (poemRef.current) {
      poemRef.current.scrollTop = poemRef.current.scrollHeight;
    }
  }, [poemLines]);

  const generateNhangsi = async () => {
    if (keyword.length === 0 || !/^[가-힣]+$/.test(keyword)) {
      setError('한글로 된 단어를 입력해주세요.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setPoemLines([]);
    setFullPoem('');

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: 'ft:gpt-4o-mini-2024-07-18:personal:pms-real:AGf4AQYG',
          messages: [
            { role: "system", content: "당신은 재치있고 웃기는 n행시를 쓰는 AI입니다. 글자수에 맞게 써주세요." },
            { role: "user", content: `'${keyword}'라는 단어로 ${keyword.length}행시 써주세요.` }
          ],
          temperature: 0.9,
          top_p: 0.97,
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const generatedPoem = data.choices[0].message.content;
      setFullPoem(generatedPoem);
      
      const lines = generatedPoem.split('\n').filter(line => line.trim());
      for (let i = 0; i < lines.length; i++) {
        setTimeout(() => {
          setPoemLines(prev => [...prev, lines[i]]);
        }, i * 1000);
      }
    } catch (err) {
      setError('n행시를 생성하는 데 문제가 발생했습니다. 다시 시도해 주세요.');
      console.error('Error generating n hang si:', err);
    }
    setIsLoading(false);
  };

  const handleCopyPoem = () => {
    navigator.clipboard.writeText(fullPoem);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const renderPoemLines = () => {
    return poemLines.map((line, index) => {
      const firstChar = line.charAt(0);
      return (
        <p key={index} className="text-xl text-gray-700 font-nanum-square leading-relaxed text-left w-full mb-2">
          <span className="font-bold text-purple-600">{firstChar}</span>{line.slice(1)}
        </p>
      );
    });
  };

  return (
    <motion.section 
      className="bg-gradient-to-br from-purple-50 to-pink-50 rounded-3xl p-8 md:p-12 text-center mb-20 shadow-lg max-w-2xl mx-auto"
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-3xl font-bold text-purple-700 mb-8 font-rbt">N행시 장인 '먀'</h2>
      <Alert>
        <p className="text-base font-semibold text-amber-800">
          주의: <span className="text-red-600">n행시를 너무 많이 생성하면 API 사용량이 증가합니다.</span><br />
          <span className="text-sm italic text-amber-700">제 통장을 살려주세요.</span>
        </p>
      </Alert>
      <div className="mb-6">
        <input
          type="text"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="한글 단어 입력 (예: 강아지)"
          className="w-full px-4 py-2 rounded-full border-2 border-purple-300 focus:border-purple-500 focus:outline-none"
        />
      </div>
      <div 
        ref={poemRef}
        className="mb-8 bg-white p-6 rounded-2xl shadow-inner min-h-[150px] max-h-[300px] overflow-y-auto flex flex-col items-start justify-start"
      >
        {isLoading ? (
          <p className="text-xl text-gray-500">n행시를 생성 중입니다...</p>
        ) : error ? (
          <p className="text-xl text-red-500">{error}</p>
        ) : poemLines.length > 0 ? (
          renderPoemLines()
        ) : (
          <p className="text-gray-500">
            <strong className="text-xl font-extrabold">한글 단어를 입력</strong>
            <span className="text-base"> 하고 버튼을 클릭하여 n행시를 생성하세요.</span>
          </p>
        )}
      </div>
      <div className="flex justify-center space-x-4">
        <motion.button
          onClick={generateNhangsi}
          className="inline-flex items-center bg-purple-500 text-white px-6 py-3 rounded-full text-lg hover:bg-purple-600 transition-colors shadow-md hover:shadow-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          disabled={isLoading}
        >
          {isLoading ? '생성 중...' : 'n행시 생성하기'} <RefreshCw className="ml-2 h-5 w-5" />
        </motion.button>
        {fullPoem && (
          <motion.button
            onClick={handleCopyPoem}
            className={`inline-flex items-center ${copied ? 'bg-green-500' : 'bg-blue-500'} text-white px-6 py-3 rounded-full text-lg hover:bg-opacity-90 transition-colors shadow-md hover:shadow-lg`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {copied ? '복사됨' : 'n행시 복사하기'} <Copy className="ml-2 h-5 w-5" />
          </motion.button>
        )}
      </div>
    </motion.section>
  );
};

export default NhangsiGenerator;
import React, { useState } from 'react';
import Header from '../components/Header';
import Book3D from '../components/Book3D';
import Footer from '../components/Footer';

import bookFront from '../images/front.png';
import bookBack from '../images/back.png';
import bookSide from '../images/side.png';

const SalesPage = () => {
  const [showMoreBooksMessage, setShowMoreBooksMessage] = useState(false);

  const books = [
    { 
      id: 1, 
      title: "멍의 기원", 
      price: 7900, 
      frontImage: bookFront,
      backImage: bookBack,
      sideImage: bookSide,
      externalLink: "https://smartstore.naver.com/myomyopoem/products/10820452408"
    },
  ];

  const handleBuyClick = (book) => {
    if (book.externalLink) {
      window.open(book.externalLink, '_blank');
    }
  };

  const handleMoreBooksClick = (e) => {
    e.preventDefault();
    setShowMoreBooksMessage(true);
  };

  const MessageModal = ({ message, onClose }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-md w-full shadow-xl transform transition-all ease-in-out duration-300">
        <p className="text-xl text-gray-800 mb-6 font-semibold">{message}</p>
        <button 
          className="w-full bg-teal-500 text-white px-6 py-3 rounded-md text-lg font-semibold hover:bg-teal-600 transition-colors shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50"
          onClick={onClose}
        >
          확인
        </button>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      <main className="container mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold text-teal-700 mb-6 text-center font-rbt">ㅁYㅇ 의 시집</h2>
        
        <section className="space-y-6 mb-10">
          {books.map((book) => (
            <div key={book.id} className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="flex items-center p-4">
                <div className="mr-4 flex-shrink-0">
                  <Book3D 
                    frontImage={book.frontImage}
                    backImage={book.backImage}
                    sideImage={book.sideImage}
                    width={140}
                    height={200}
                  />
                </div>
                <div className="flex-grow flex flex-col items-start">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2">{book.title}</h3>
                  <span className="text-lg font-bold text-teal-600 mb-3">{book.price.toLocaleString()}원</span>
                  <button
                    className="bg-teal-500 text-white px-6 py-2 rounded-md text-sm hover:bg-teal-600 transition-colors shadow-md hover:shadow-lg"
                    onClick={() => handleBuyClick(book)}
                  >
                    구매하기
                  </button>
                </div>
              </div>
            </div>
          ))}
        </section>

        <div className="text-center">
          <button 
            onClick={handleMoreBooksClick}
            className="inline-block bg-teal-500 text-white px-6 py-2 rounded-full text-sm hover:bg-teal-600 transition-colors shadow-md hover:shadow-lg"
          >
            더 많은 시집 보기
          </button>
        </div>
      </main>

      {showMoreBooksMessage && (
        <MessageModal 
          message="꼭 준비할게요. 아직은 없어요."
          onClose={() => setShowMoreBooksMessage(false)}
        />
      )}

      <Footer />
    </div>
  );
};

export default SalesPage;
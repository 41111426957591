import React, { useState, useEffect } from 'react';
import { ShoppingCart, Users, CalendarDays} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/Header';
import Footer from '../components/Footer';

import RandomPoemGenerator from '../components/Main/RandomPoemGenerator';
import NhangsiGenerator from '../components/Main/NhangsiGenerator';

import Slider from '../components/Main/Slider';
import GoogleMap from '../components/GoogleMap';
import { featuredPoems } from '../data/featuredPoems';


const MainPage = () => {
  const [currentPoemIndex, setCurrentPoemIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPoemIndex((prevIndex) => (prevIndex + 1) % featuredPoems.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const formatPoemText = (text) => {
    // 이미 줄바꿈이 있는 경우 그대로 반환
    if (text.includes('\n')) {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < text.split('\n').length - 1 && <br />}
        </React.Fragment>
      ));
    }
    
    return text;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100 font-mapo-gold">
      <Header />

      <main className="container mx-auto px-6 py-12">
        <div className="h-[500px] mb-12"> {/* Adjusted height and added margin */}
          <Slider />
        </div>

        <section className="grid md:grid-cols-3 gap-8 mb-20">
          <motion.div 
            className="bg-teal-500 text-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all hover:-translate-y-2"
            whileHover={{ scale: 1.05 }}
          >
            <ShoppingCart className="h-12 w-12 mb-4" />
            <h3 className="text-2xl font-bold mb-4 font-rbt">시집 구매하기</h3>
            <p className="mb-6">당신의 멍을 들여다보세요.</p>
            <a href="/sales" className="inline-block bg-white text-teal-500 px-6 py-2 rounded-lg hover:bg-gray-100 transition-colors">
              구매하기
            </a>
          </motion.div>
          <motion.div 
            className="bg-blue-500 text-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all hover:-translate-y-2"
            whileHover={{ scale: 1.05 }}
          >
            <Users className="h-12 w-12 mb-4" />
            <h3 className="text-2xl font-bold mb-4 font-rbt">작가와의 만남</h3>
            <p className="mb-6">ㅁYㅇ는 묘연하게 나타납니다. <br/>캘린더에서 묘를 찾아보세요.</p>
            <a href="/meetauthor" className="inline-block bg-white text-blue-500 px-6 py-2 rounded-lg hover:bg-gray-100 transition-colors">
              신청하기
            </a>
          </motion.div>
          <motion.div 
            className="bg-green-500 text-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all hover:-translate-y-2"
            whileHover={{ scale: 1.05 }}
          >
            <CalendarDays className="h-12 w-12 mb-4" />
            <h3 className="text-2xl font-bold mb-4 font-rbt">심리테스트</h3>
            <p className="mb-6">여러분과 맞는 시집을 추천해드릴게요.</p>
            <a href="psychtest" className="inline-block bg-white text-green-500 px-6 py-2 rounded-lg hover:bg-gray-100 transition-colors">
              참여하기
            </a>
          </motion.div>
        </section>
        
        <motion.section 
          className="bg-white bg-opacity-90 rounded-3xl p-12 text-center mb-20 shadow-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="text-3xl font-bold text-teal-700 mb-8 font-rbt">오늘의 추천 구절</h2>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentPoemIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <blockquote className="italic text-2xl text-gray-700 mb-6 leading-relaxed font-nanum-square">
                "{formatPoemText(featuredPoems[currentPoemIndex].text)}"
              </blockquote>
              <p className="text-gray-600 text-xl mb-4">- {featuredPoems[currentPoemIndex].author} "{featuredPoems[currentPoemIndex].title}"</p>
              <motion.button
                className="flex items-center justify-center space-x-2 mx-auto text-teal-500 hover:text-teal-600 transition-colors"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
              </motion.button>
            </motion.div>
          </AnimatePresence>
        </motion.section>

        <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
          <h2 className="text-2xl font-semibold mb-6 text-teal-700 font-rbt">묘와 전단지</h2>
          <div className="bg-teal-100 border-l-4 border-teal-500 text-teal-700 p-4 mb-6 rounded-r-lg">
            <p className="font-medium text-lg">
              💡 <span className="font-bold">특별 할인!</span> 전단지를 가져오시면 시집 구매 시 할인해 드립니다.
            </p>
            <p className="mt-2 text-sm italic">
              * 자세한 내용은 매장에서 확인하세요.
            </p>
          </div>
          <GoogleMap 
            src="https://www.google.com/maps/d/u/0/embed?mid=1OpQz22msxM4WjQZ7U1_yUInCVS5gTxU&ehbc=2E312F"
            title="묘의 전단지를 들고 오시면 할인을 받을 수 있다는 말이죠."
          />
        </div>

        <RandomPoemGenerator />

        <NhangsiGenerator />

      </main>

      <Footer />
    </div>
  );
};

export default MainPage;
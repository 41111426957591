import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { firestore } from '../firebase';
import { collection, addDoc, query, orderBy, limit, onSnapshot, doc, updateDoc, arrayUnion, arrayRemove, getDoc, deleteDoc } from 'firebase/firestore';
import Header from '../components/ToOurReaders/Header';
import Footer from '../components/Footer';
import { Heart, MessageCircle, Edit, Trash2 } from 'lucide-react';

const TalkToMyoPage = () => {
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: '', content: '' });
  const [newComments, setNewComments] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [editingPost, setEditingPost] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const q = query(collection(firestore, 'real_posts'), orderBy('createdAt', 'desc'), limit(postsPerPage * currentPage));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedPosts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        comments: Array.isArray(doc.data().comments) ? doc.data().comments : [],
        likes: Array.isArray(doc.data().likes) ? doc.data().likes : [],
      }));
      setPosts(fetchedPosts);
    });

    return () => unsubscribe();
  }, [currentPage, postsPerPage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPost.title.trim() || !newPost.content.trim()) return;

    await addDoc(collection(firestore, 'real_posts'), {
      title: newPost.title,
      content: newPost.content,
      authorId: user.uid,
      authorName: user.displayName || user.email,
      createdAt: new Date(),
      likes: [],
      comments: []
    });

    setNewPost({ title: '', content: '' });
  };

  const handleEdit = async (postId, updatedPost) => {
    const postRef = doc(firestore, 'real_posts', postId);
    await updateDoc(postRef, updatedPost);
    setEditingPost(null);
  };

  const handleDelete = async (postId) => {
    if (window.confirm('정말로 이 게시물을 삭제하시겠습니까?')) {
      await deleteDoc(doc(firestore, 'real_posts', postId));
    }
  };

  const handleCommentSubmit = async (postId) => {
    if (!newComments[postId]?.trim()) return;

    const postRef = doc(firestore, 'real_posts', postId);
    await updateDoc(postRef, {
      comments: arrayUnion({
        content: newComments[postId],
        authorId: user.uid,
        authorName: user.displayName || user.email,
        createdAt: new Date()
      })
    });

    setNewComments(prev => ({ ...prev, [postId]: '' }));
  };

  const handleLike = async (postId) => {
    if (!user) return;

    const postRef = doc(firestore, 'real_posts', postId);
    const postSnap = await getDoc(postRef);
    const post = postSnap.data();
    
    if (!post) return;

    const likes = Array.isArray(post.likes) ? post.likes : [];
    
    if (likes.includes(user.uid)) {
      await updateDoc(postRef, {
        likes: arrayRemove(user.uid)
      });
    } else {
      await updateDoc(postRef, {
        likes: arrayUnion(user.uid)
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 via-teal-50 to-green-100">
      <Header />
      
      <main className="container mx-auto px-2 py-4 sm:px-4 sm:py-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-teal-700 mb-4 sm:mb-6 text-center font-rbt">ㅁYㅇ에게 말하기</h1>
        
        {user && (
          <form onSubmit={handleSubmit} className="mb-4 sm:mb-6">
            <input
              type="text"
              value={newPost.title}
              onChange={(e) => setNewPost(prev => ({ ...prev, title: e.target.value }))}
              placeholder="제목"
              className="w-full p-2 mb-2 border border-gray-300 rounded text-sm"
              required
            />
            <textarea
              value={newPost.content}
              onChange={(e) => setNewPost(prev => ({ ...prev, content: e.target.value }))}
              placeholder="내용"
              className="w-full p-2 mb-2 border border-gray-300 rounded text-sm"
              required
              rows="4"
            />
            <button type="submit" className="bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 transition duration-300">
              게시하기
            </button>
          </form>
        )}

        <div className="space-y-4 sm:space-y-5">
          {posts.map((post) => (
            <div key={post.id} className="bg-white p-4 rounded shadow hover:shadow-md transition duration-300">
              {editingPost === post.id ? (
                <form onSubmit={(e) => { e.preventDefault(); handleEdit(post.id, { title: e.target.title.value, content: e.target.content.value }); }}>
                  <input
                    name="title"
                    defaultValue={post.title}
                    className="w-full p-2 mb-3 border border-gray-300 rounded text-sm"
                    required
                  />
                  <textarea
                    name="content"
                    defaultValue={post.content}
                    className="w-full p-2 mb-3 border border-gray-300 rounded text-sm"
                    required
                    rows="3"
                  />
                  <button type="submit" className="bg-green-500 text-white px-2 py-1 rounded text-xs hover:bg-green-600 transition duration-300 mr-2">
                    저장
                  </button>
                  <button onClick={() => setEditingPost(null)} className="bg-gray-500 text-white px-2 py-1 rounded text-xs hover:bg-gray-600 transition duration-300">
                    취소
                  </button>
                </form>
              ) : (
                <>
                  <h2 className="text-lg font-semibold mb-2">{post.title}</h2>
                  <p className="text-xs text-gray-500 mb-2">{post.authorName} • {new Date(post.createdAt.toDate()).toLocaleString()}</p>
                  <p className="text-sm mb-3 whitespace-pre-wrap">{post.content}</p>
                </>
              )}
              <div className="flex justify-between items-center mb-3">
                <div className="flex items-center space-x-3">
                  <button onClick={() => handleLike(post.id)} className="flex items-center text-blue-500 hover:text-blue-600 transition duration-300">
                    <Heart className={`mr-1 ${post.likes.includes(user?.uid) ? 'fill-current' : ''}`} size={16} />
                    <span className="text-xs">{post.likes.length}</span>
                  </button>
                  <div className="flex items-center text-gray-500">
                    <MessageCircle className="mr-1" size={16} />
                    <span className="text-xs">{post.comments.length}</span>
                  </div>
                </div>
                {user && user.uid === post.authorId && (
                  <div className="flex items-center space-x-2">
                    <button onClick={() => setEditingPost(post.id)} className="text-green-500 hover:text-green-600 transition duration-300">
                      <Edit size={16} />
                    </button>
                    <button onClick={() => handleDelete(post.id)} className="text-red-500 hover:text-red-600 transition duration-300">
                      <Trash2 size={16} />
                    </button>
                  </div>
                )}
              </div>
              {user && (
                <form onSubmit={(e) => { e.preventDefault(); handleCommentSubmit(post.id); }} className="mb-3">
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={newComments[post.id] || ''}
                      onChange={(e) => setNewComments(prev => ({ ...prev, [post.id]: e.target.value }))}
                      placeholder="댓글을 작성해주세요..."
                      className="flex-grow p-1 border border-gray-300 rounded-l text-xs"
                    />
                    <button type="submit" className="bg-blue-500 text-white px-2 py-1 rounded-r text-xs hover:bg-blue-600 transition duration-300">
                      작성
                    </button>
                  </div>
                </form>
              )}
              <div className="space-y-2">
                {Array.isArray(post.comments) && post.comments.map((comment, index) => (
                  <div key={index} className="bg-gray-100 p-2 rounded">
                    <p className="text-xs font-semibold mb-1">{comment.authorName}</p>
                    <p className="text-sm mb-1">{comment.content}</p>
                    <p className="text-xs text-gray-500">{new Date(comment.createdAt.toDate()).toLocaleString()}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {posts.length === postsPerPage * currentPage && (
          <button onClick={() => setCurrentPage(prev => prev + 1)} className="mt-4 sm:mt-6 bg-blue-500 text-white px-3 py-1 rounded text-sm hover:bg-blue-600 transition duration-300 mx-auto block">
            더 보기
          </button>
        )}
      </main>

      <Footer />
    </div>
  );
};

export default TalkToMyoPage;